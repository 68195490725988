import React from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import UserInfoCard from "../UserInfoCard/UserInfoCard";
import GithubCard from "../GithubCard/GithubCard";
import InterviewStatsCard from "../InterviewStatsCard/InterviewStatsCard";
import StartInterviewCard from "../StartInterviewCard/StartInterviewCard";
import AssessmentsCard from "../AssessmentsCard/AssessmentsCard";
import FeedbackCard from "../FeedbackCard/FeedbackCard";

const Home = () => {
  return (
    <Grid
      templateColumns={{
        base: "1fr",
        md: "repeat(3, 1fr)",
      }}
      gap={6}
      mt={32}
      px={4}
      maxW="1400px"
      w="100%"
    >
      <GridItem colSpan={{ base: 1, md: 1 }}>
        <StartInterviewCard />
      </GridItem>
      <GridItem colSpan={{ base: 1, md: 2 }}>
        <InterviewStatsCard />
      </GridItem>
      <GridItem colSpan={{ base: 1, md: 2 }}>
        <AssessmentsCard />
      </GridItem>
      <GridItem colSpan={{ base: 1, md: 1 }}>
        <FeedbackCard />
      </GridItem>
    </Grid>
  );
};

export default Home; 