import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TopNav from "../../Components/TopNav/TopNav";
import "./Assessment.scss";
import { ChevronLeft } from "lucide-react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import useAnalytics from "../../analytics/useAnalytics";

const Assessment = () => {
  const assessment = useSelector((state) => state.assessment);
  const { logEvent } = useAnalytics();
  const navigate = useNavigate();

  useEffect(() => {
    if (!assessment || !assessment.overallScore) {
      navigate("/dashboard/assessments");
    } else {
      logEvent('Assessment', 'View Assessment');
    }
  }, [assessment, navigate, logEvent]);

  if (!assessment || !assessment.overallScore) {
    return null; // Return null while redirecting
  }

  const chartData = assessment.categories
    ? assessment.categories.map((category) => ({
        name: category.name,
        score: category.score,
      }))
    : [];

  const getBarColor = (score) => {
    if (score <= 3) return "#ff4d4d"; // Red for low scores
    if (score <= 7) return "#ffd700"; // Yellow for medium scores
    return "#4caf50"; // Green for high scores
  };

  return (
    <div className="assessment-page">
      <button 
        className="back-button"
        onClick={() => navigate('/dashboard/assessments')}
      >
        <ChevronLeft />
      </button>
      <div className="assessment-screen">
        <h1 className="assessment-title">Candidate Assessment</h1>

        <div className="assessment-header">
          <div className="assessment-meta">
            <p>
              <span>Difficulty:</span> {assessment.difficulty || "N/A"}
            </p>
            <p>
              <span>Time Limit:</span>{" "}
              {assessment.timeLimit
                ? `${assessment.timeLimit / 60000} minutes`
                : "N/A"}
            </p>
          </div>
          <div className="overall-score">
            <h2>
              Overall Score:{" "}
              <span
                className={`score ${
                  assessment.overallScore <= 3
                    ? "low"
                    : assessment.overallScore <= 7
                    ? "medium"
                    : "high"
                }`}
              >
                {assessment.overallScore || "N/A"}
              </span>
              /10
            </h2>
          </div>
        </div>

        <div className="assessment-section overall-feedback">
          <h2>Overall Feedback</h2>
          <p>
            {assessment.overallFeedback || "No overall feedback available."}
          </p>
        </div>

        <div className="assessment-section category-scores">
          <h2>Category Scores</h2>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={chartData}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid
                strokeDasharray="3 3"
                stroke="rgba(255, 255, 255, 0.1)"
              />
              <XAxis
                dataKey="name"
                tick={{ fontSize: 12, fill: "#e0e0e0" }}
                interval={0}
                angle={-45}
                textAnchor="end"
                height={80}
              />
              <YAxis
                domain={[0, 10]}
                tick={{ fontSize: 12, fill: "#e0e0e0" }}
              />
              <Tooltip
                contentStyle={{
                  backgroundColor: "#2c2c2c",
                  border: "none",
                  borderRadius: "4px",
                }}
                labelStyle={{ color: "#e0e0e0" }}
                itemStyle={{ color: "#e0e0e0" }}
              />
              <Legend wrapperStyle={{ fontSize: 12, color: "#e0e0e0" }} />
              <Bar dataKey="score">
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={getBarColor(entry.score)} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className="assessment-section detailed-feedback">
          <h2>Detailed Feedback</h2>
          {assessment.categories &&
            assessment.categories.map((category, index) => (
              <div key={index} className="category-feedback">
                <h3>
                  {category.name + " - "}
                  <span
                    className={`score ${
                      category.score <= 3
                        ? "low"
                        : category.score <= 7
                        ? "medium"
                        : "high"
                    }`}
                  >
                    {category.score || "N/A"}
                  </span>
                  /10
                </h3>
                <p>
                  <strong>Feedback:</strong>{" "}
                  {category.feedback ||
                    "No feedback available for this category."}
                </p>
                <p>
                  <strong>Reference:</strong>{" "}
                  {category.reference ||
                    "No reference available for this category."}
                </p>
              </div>
            ))}
        </div>

        <div className="assessment-section strengths-and-improvements">
          <div className="strengths">
            <h2>Strengths</h2>
            {assessment.strengths && assessment.strengths.length > 0 ? (
              <ul>
                {assessment.strengths.map((strength, index) => (
                  <li key={index}>{strength}</li>
                ))}
              </ul>
            ) : (
              <p>No strengths listed.</p>
            )}
          </div>

          <div className="improvement-areas">
            <h2>Areas for Improvement</h2>
            {assessment.areasForImprovement &&
            assessment.areasForImprovement.length > 0 ? (
              <ul>
                {assessment.areasForImprovement.map((area, index) => (
                  <li key={index}>{area}</li>
                ))}
              </ul>
            ) : (
              <p>No areas for improvement listed.</p>
            )}
          </div>
        </div>

        <div className="assessment-section final-recommendation">
          <h2>Final Recommendation</h2>
          <p>
            {assessment.finalRecommendation ||
              "No final recommendation available."}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Assessment;
