import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import {
  Box,
  Heading,
  Text,
  List,
  ListItem,
  Flex,
  Spacer,
  Button,
  useToast,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { updateAssessment } from '../../ducks/slices/assessmentSlice';

const shouldUseV2 = (createdAt) => {
  const assessmentDate = new Date(createdAt);
  const v2ReleaseDate = new Date('2023-11-19');
  return assessmentDate >= v2ReleaseDate;
};

const AssessmentsCard = () => {
  const [assessments, setAssessments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user && user.userId) {
      fetchAssessments();
    }
  }, [user]);

  const fetchAssessments = async () => {
    try {
      const response = await axios.get('/info/assessments');
      setAssessments(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching assessments:", error);
      toast({
        title: "Error",
        description: "Failed to fetch assessments",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const handleAssessmentClick = (assessment) => {
    if (shouldUseV2(assessment.created_at)) {
      navigate(`/assessment/v2/${assessment.assessment_id}`);
    } else {
      dispatch(updateAssessment({
        overallScore: assessment.overall_score,
        overallFeedback: assessment.overall_feedback,
        categories: assessment.categories,
        strengths: assessment.strengths,
        areasForImprovement: assessment.areas_for_improvement,
        finalRecommendation: assessment.final_recommendation,
        difficulty: assessment.difficulty,
        timeLimit: assessment.time_limit
      }));
      navigate("/assessment");
    }
  };

  const handleViewAll = () => {
    navigate('/dashboard/assessments');
  };

  if (isLoading) {
    return (
      <Box bg="#1e1e1e" p={6} borderRadius="lg" boxShadow="xl" color="white" height="400px">
        <Text>Loading assessments...</Text>
      </Box>
    );
  }

  return (
    <Box bg="#1e1e1e" p={6} borderRadius="lg" boxShadow="xl" color="white" height="400px">
      <Flex align="center" justify="space-between" mb={4}>
        <Heading size="md">Recent Assessments</Heading>
        <Button
          size="sm"
          bg="#673FD7"
          color="white"
          _hover={{ 
            bg: '#5a36bc',
            transform: 'translateY(-2px)',
            transition: 'transform 0.2s',
          }}
          onClick={handleViewAll}
        >
          View All
        </Button>
      </Flex>
      
      <Box 
        overflowY="auto" 
        maxH="300px" 
        pr={4}
        css={{
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'rgba(255, 255, 255, 0.1)',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(255, 255, 255, 0.2)',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: 'rgba(255, 255, 255, 0.3)',
          },
        }}
      >
        {assessments.length === 0 ? (
          <Text color="gray.400" textAlign="center" mt={4}>
            No assessments yet. Start an interview to get feedback!
          </Text>
        ) : (
          <>
            {/* Table Header */}
            <Flex 
              bg="rgba(255, 255, 255, 0.1)" 
              p={3} 
              borderRadius="md" 
              mb={2} 
              fontWeight="bold"
            >
              <Text flex="1">Date</Text>
              <Text flex="1">Score</Text>
              <Text flex="1">Difficulty</Text>
              <Box width="24px" /> {/* Space for chevron */}
            </Flex>

            {/* Table Rows */}
            <List spacing={2}>
              {assessments.map((assessment) => (
                <ListItem
                  key={assessment.assessment_id}
                  bg="rgba(255, 255, 255, 0.1)" 
                  p={3}
                  borderRadius="md"
                  _hover={{ bg: "rgba(255, 255, 255, 0.2)", cursor: "pointer" }}
                  onClick={() => handleAssessmentClick(assessment)}
                >
                  <Flex align="center">
                    <Text flex="1">
                      {new Date(assessment.created_at).toLocaleDateString(undefined, {
                        month: 'long',
                        day: 'numeric'
                      })}
                    </Text>
                    <Text
                      flex="1"
                      fontWeight="bold"
                      color={
                        assessment.overall_score <= 3 ? "#ff4d4d" :
                        assessment.overall_score <= 7 ? "#ffd700" :
                        "#4caf50"
                      }
                    >
                      {assessment.overall_score}/10
                    </Text>
                    <Text flex="1" color="gray.400">
                      {assessment.difficulty}
                    </Text>
                    <ChevronRightIcon boxSize={6} color="gray.400" />
                  </Flex>
                </ListItem>
              ))}
            </List>
          </>
        )}
      </Box>
    </Box>
  );
};

export default AssessmentsCard;
