// store.js
import { configureStore } from "@reduxjs/toolkit";
import messagesReducer from "./slices/messagesSlice";
import coderPadReducer from "./slices/coderPadSlice";
import assessmentReducer from "./slices/assessmentSlice";
import userReducer from "./slices/userSlice";
import transcriptReducer from "./slices/transcriptSlice";

const store = configureStore({
  reducer: {
    messages: messagesReducer,
    coderPad: coderPadReducer,
    assessment: assessmentReducer,
    user: userReducer,
    transcript: transcriptReducer
  },
});

export default store;
