import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Heading,
  Text,
  Card,
  Button,
  Icon,
} from '@chakra-ui/react';
import { FaCode } from 'react-icons/fa';
import useAnalytics from '../../analytics/useAnalytics';

const StartInterviewCard = () => {
  const navigate = useNavigate();
  const { logEvent } = useAnalytics();

  const handleStartInterview = () => {
    logEvent('Dashboard', 'Start Interview', 'Button Click');
    navigate('/interview');
  };

  return (
    <Card
      bg="#1e1e1e"
      p={6}
      borderRadius="lg"
      boxShadow="xl"
      color="white"
      height="400px"
      position="relative"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
        textAlign="center"
      >
        <Icon as={FaCode} w={12} h={12} color="#673FD7" mb={6} />
        <Heading size="lg" mb={4}>
          Start Interview
        </Heading>
        <Text color="gray.300" fontSize="lg">
          Ready to practice? Click here to start your next interview session!
        </Text>
        <Button
          mt={8}
          bg="#673FD7"
          color="white"
          size="lg"
          _hover={{ 
            bg: '#5a36bc',
            transform: 'translateY(-2px)',
            transition: 'transform 0.2s',
          }}
          px={8}
          onClick={handleStartInterview}
        >
          Start Now
        </Button>
      </Box>
    </Card>
  );
};

export default StartInterviewCard;
