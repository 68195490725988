import "./CodingTimeline.scss";
import { useState, useEffect } from "react";
import Editor from "@monaco-editor/react";

const CodingTimeline = ({ interactions, timeLimit, onTimeChange }) => {
  const [sliderValue, setSliderValue] = useState(0);
  const [currentCode, setCurrentCode] = useState("");

  useEffect(() => {
    if (interactions.length > 0) {
      const codeSnapshots = interactions
        .filter(interaction => interaction.type === "HumanMessage")
        .map(interaction => {
          try {
            return {
              timestamp: interaction.normalizedTimestamp,
              code: interaction.candidateCoderPad || ""
            };
          } catch (e) {
            return null;
          }
        })
        .filter(Boolean);

      if (codeSnapshots.length > 0) {
        const currentTime = (sliderValue / 100) * timeLimit;
        
        const currentSnapshot = codeSnapshots.reduce((prev, curr) => {
          if (curr.timestamp <= currentTime) return curr;
          return prev;
        });

        setCurrentCode(currentSnapshot.code);
      }
    }
  }, [interactions, sliderValue, timeLimit]);

  useEffect(() => {
    const currentTime = (sliderValue / 100) * timeLimit;
    onTimeChange(currentTime);
  }, [sliderValue, timeLimit]);

  useEffect(() => {
    const handleTimelineSync = (event) => {
      setSliderValue(event.detail.percentage);
    };

    window.addEventListener('timelineSync', handleTimelineSync);

    return () => {
      window.removeEventListener('timelineSync', handleTimelineSync);
    };
  }, []);

  const handleSliderChange = (e) => {
    setSliderValue(Number(e.target.value));
  };

  const formatTime = (milliseconds) => {
    const minutes = Math.floor(milliseconds / 60000);
    const seconds = Math.floor((milliseconds % 60000) / 1000);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="coding-timeline">
      <div className="editor-container">
        <Editor
          height="100%"
          width="100%"
          theme="vs-dark"
          language="python"
          value={currentCode}
          options={{
            readOnly: true,
            fontSize: 14,
            minimap: { enabled: false },
            scrollbar: { vertical: 'hidden' },
            lineNumbers: 'off',
            folding: false,
            wordWrap: 'on'
          }}
        />
      </div>
      <div className="timeline-controls">
        <input
          type="range"
          min="0"
          max="100"
          value={sliderValue}
          onChange={handleSliderChange}
          className="timeline-slider"
        />
        <div className="timeline-labels">
          <span>0:00</span>
          <span>{formatTime((timeLimit * sliderValue) / 100)}</span>
          <span>{formatTime(timeLimit)}</span>
        </div>
      </div>
    </div>
  );
};

export default CodingTimeline;