import { createSlice } from "@reduxjs/toolkit";

const coderPadSlice = createSlice({
  name: "coderPad",
  initialState: {
    candidateEditorValue: ``
  },
  reducers: {
    setCandidateEditorValue: (state, action) => {
      state.candidateEditorValue = action.payload;
    }
  },
});

export const {
  setCandidateEditorValue
} = coderPadSlice.actions;

export default coderPadSlice.reducer;
