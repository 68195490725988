import { createSlice } from '@reduxjs/toolkit';

const assessmentSlice = createSlice({
  name: 'assessment',
  initialState: {
    overallScore: null,
    overallFeedback: '',
    categories: [],
    strengths: [],
    areasForImprovement: [],
    finalRecommendation: '',
    difficulty: '',
    timeLimit: 0,
    assessmentId: null,
  },
  reducers: {
    updateAssessment: (state, action) => {
      return action.payload;
    },
  },
});

export const { updateAssessment } = assessmentSlice.actions;

export default assessmentSlice.reducer;
