import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  VStack,
  Heading,
  Text,
  Input,
  Button,
  Divider,
  Icon,
  Alert,
  AlertIcon,
  InputGroup,
  InputRightElement,
  Progress,
} from "@chakra-ui/react";
import { FaGithub, FaLinkedin, FaEye, FaEyeSlash, FaGoogle } from "react-icons/fa";
import {
  ChevronLeftIcon,
  ArrowForwardIcon,
  ArrowBackIcon,
} from "@chakra-ui/icons";
import Logo from "../../Components/Logo/Logo";
import "./SignUp.scss";
import useAnalytics from "../../analytics/useAnalytics";
import axios from "axios";
import { updateUser } from "../../ducks/slices/userSlice";

const SignUp = () => {
  const [step, setStep] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { logEvent } = useAnalytics();

  const validateStep = (currentStep) => {
    setErrors([]);
    switch (currentStep) {
      case 1:
        if (!firstName || !lastName) {
          setErrors(["Please fill in all fields"]);
          return false;
        }
        break;
      case 2:
        if (!username || !email) {
          setErrors(["Please fill in all fields"]);
          return false;
        }
        break;
      case 3:
        if (!password || !confirmPassword) {
          setErrors(["Please fill in all fields"]);
          return false;
        }
        if (password !== confirmPassword) {
          setErrors(["Passwords do not match"]);
          return false;
        }
        break;
      default:
        return true;
    }
    return true;
  };

  const handleNext = () => {
    if (validateStep(step)) {
      setStep(step + 1);
    }
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleRegister = async () => {
    if (!validateStep(3)) return;

    try {
      const res = await axios.post(`/auth/register`, {
        username,
        email,
        password,
        first_name: firstName,
        last_name: lastName,
      });

      dispatch(
        updateUser({
          username: res.data.username,
          email: res.data.email,
          firstName: res.data.first_name,
          lastName: res.data.last_name,
          githubId: res.data.github_id,
          linkedinId: res.data.linkedin_id,
        })
      );
      navigate("/dashboard");
      logEvent("Auth", "Register", "Success");
    } catch (error) {
      logEvent("Auth", "Register", "Failed");
      if (error.response && error.response.data) {
        setErrors(
          Array.isArray(error.response.data)
            ? error.response.data
            : [error.response.data]
        );
      } else {
        setErrors(["An error occurred. Please try again."]);
      }
    }
  };

  const handleGithubLogin = () => {
    logEvent("Auth", "Github Login", "Clicked");
    const state = "github";
    window.location.assign(
      `https://github.com/login/oauth/authorize?client_id=${process.env.REACT_APP_GITHUB_CLIENT_ID}&state=${state}`
    );
  };

  const handleLinkedinLogin = () => {
    logEvent("Auth", "LinkedIn Login", "Clicked");
    const state = "linkedin";
    window.location.assign(
      `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${
        process.env.REACT_APP_LINKEDIN_CLIENT_ID
      }&redirect_uri=${encodeURIComponent(
        process.env.REACT_APP_LINKEDIN_REDIRECT_URI
      )}&state=${state}&scope=openid%20profile%20email`
    );
  };

  const handleGoogleLogin = () => {
    logEvent("Auth", "Google Login", "Clicked");
    const state = "google";
    window.location.assign(
      `https://accounts.google.com/o/oauth2/v2/auth?` +
      `client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&` +
      `redirect_uri=${encodeURIComponent(process.env.REACT_APP_GOOGLE_REDIRECT_URI)}&` +
      `response_type=code&` +
      `scope=email%20profile&` +
      `state=${state}`
    );
  };

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <VStack spacing={4}>
            <Box width="100%" display="flex" gap={4}>
              <Box flex={1}>
                <Text color="gray.400" mb={2} fontSize="sm">
                  First Name
                </Text>
                <Input
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  size="lg"
                  bg="gray.700"
                  border="none"
                  color="white"
                  _hover={{ bg: "gray.600" }}
                  _focus={{ bg: "gray.600", boxShadow: "none" }}
                />
              </Box>
              <Box flex={1}>
                <Text color="gray.400" mb={2} fontSize="sm">
                  Last Name
                </Text>
                <Input
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  size="lg"
                  bg="gray.700"
                  border="none"
                  color="white"
                  _hover={{ bg: "gray.600" }}
                  _focus={{ bg: "gray.600", boxShadow: "none" }}
                />
              </Box>
            </Box>
          </VStack>
        );
      case 2:
        return (
          <VStack spacing={4}>
            <Box width="100%">
              <Text color="gray.400" mb={2} fontSize="sm">
                Username
              </Text>
              <Input
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                size="lg"
                bg="gray.700"
                border="none"
                color="white"
                _hover={{ bg: "gray.600" }}
                _focus={{ bg: "gray.600", boxShadow: "none" }}
              />
            </Box>
            <Box width="100%">
              <Text color="gray.400" mb={2} fontSize="sm">
                Email
              </Text>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                size="lg"
                bg="gray.700"
                border="none"
                color="white"
                _hover={{ bg: "gray.600" }}
                _focus={{ bg: "gray.600", boxShadow: "none" }}
              />
            </Box>
          </VStack>
        );
      case 3:
        return (
          <VStack spacing={4}>
            <Box width="100%">
              <Text color="gray.400" mb={2} fontSize="sm">
                Password
              </Text>
              <InputGroup size="lg">
                <Input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  size="lg"
                  bg="gray.700"
                  border="none"
                  color="white"
                  _hover={{ bg: "gray.600" }}
                  _focus={{ bg: "gray.600", boxShadow: "none" }}
                />
                <InputRightElement width="3rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    bg="transparent"
                    _hover={{ bg: "transparent" }}
                    _active={{ bg: "transparent" }}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <Icon
                      as={showPassword ? FaEyeSlash : FaEye}
                      color="gray.400"
                      _hover={{ color: "white" }}
                    />
                  </Button>
                </InputRightElement>
              </InputGroup>
            </Box>
            <Box width="100%">
              <Text color="gray.400" mb={2} fontSize="sm">
                Confirm Password
              </Text>
              <InputGroup size="lg">
                <Input
                  type={showPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  size="lg"
                  bg="gray.700"
                  border="none"
                  color="white"
                  _hover={{ bg: "gray.600" }}
                  _focus={{ bg: "gray.600", boxShadow: "none" }}
                />
                <InputRightElement width="3rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    bg="transparent"
                    _hover={{ bg: "transparent" }}
                    _active={{ bg: "transparent" }}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <Icon
                      as={showPassword ? FaEyeSlash : FaEye}
                      color="gray.400"
                      _hover={{ color: "white" }}
                    />
                  </Button>
                </InputRightElement>
              </InputGroup>
            </Box>
          </VStack>
        );
      default:
        return null;
    }
  };

  return (
    <Box className="signup-page">
      <div className="signup-gradient-background" />
      <div className="signup-content">
        <Box
          className="signup-card"
          bg="rgba(24, 24, 27, 0.9)"
          backdropFilter="blur(10px)"
        >
          <VStack spacing={6} align="stretch">
            <Box position="absolute" top={6} left={6}>
              <Button
                leftIcon={<ChevronLeftIcon width={6} height={6} />}
                variant="ghost"
                color="gray.400"
                _hover={{ color: "white", bg: "transparent" }}
                onClick={() => navigate("/")}
                p={0}
              />
            </Box>

            <Heading size="lg" textAlign="center" color="white">
              Create an Account
            </Heading>

            <Progress
              value={(step / 3) * 100}
              size="sm"
              colorScheme="purple"
              borderRadius="full"
              bg="gray.700"
              mb={4}
            />
            
            {errors.length > 0 && (
              <VStack spacing={2}>
                {errors.map((error, index) => (
                  <Alert
                    key={index}
                    status="error"
                    borderRadius="md"
                    bg="red.900"
                    color="white"
                  >
                    <AlertIcon color="red.200" />
                    {error}
                  </Alert>
                ))}
              </VStack>
            )}

            {renderStepContent()}

            <Box display="flex" gap={4}>
              {step > 1 && (
                <Button
                  leftIcon={<ArrowBackIcon />}
                  variant="outline"
                  color="white"
                  borderColor="gray.600"
                  size="lg"
                  onClick={handleBack}
                  flex={1}
                >
                  Back
                </Button>
              )}
              {step < 3 ? (
                <Button
                  rightIcon={<ArrowForwardIcon />}
                  bg="#673FD7"
                  color="white"
                  size="lg"
                  _hover={{ bg: "#5a36bc" }}
                  onClick={handleNext}
                  flex={1}
                >
                  Next
                </Button>
              ) : (
                <Button
                  bg="#673FD7"
                  color="white"
                  size="lg"
                  _hover={{ bg: "#5a36bc" }}
                  onClick={handleRegister}
                  flex={1}
                >
                  Create Account
                </Button>
              )}
            </Box>

            {step === 1 && (
              <>
                <Divider borderColor="gray.600" />
                <Button
                  leftIcon={<Icon as={FaGoogle} />}
                  bg="#ffffff"
                  color="#000000"
                  variant="solid"
                  size="lg"
                  _hover={{ bg: "#f2f2f2" }}
                  onClick={handleGoogleLogin}
                >
                  Continue with Google
                </Button>
                <Button
                  leftIcon={<Icon as={FaGithub} />}
                  bg="#24292e"
                  color="white"
                  variant="solid"
                  size="lg"
                  _hover={{ bg: "#2f363d" }}
                  onClick={handleGithubLogin}
                >
                  Continue with GitHub
                </Button>
                <Button
                  leftIcon={<Icon as={FaLinkedin} />}
                  bg="#0a66c2"
                  color="white"
                  variant="solid"
                  size="lg"
                  _hover={{ bg: "#004182" }}
                  onClick={handleLinkedinLogin}
                >
                  Continue with LinkedIn
                </Button>
              </>
            )}

            <Text color="gray.400" fontSize="sm" textAlign="center">
              Already have an account?{" "}
              <Button
                variant="link"
                color="#673FD7"
                onClick={() => navigate("/auth/signin")}
              >
                Sign In
              </Button>
            </Text>
          </VStack>
        </Box>
      </div>
    </Box>
  );
};

export default SignUp;
