import { createSlice } from '@reduxjs/toolkit';

const messagesSlice = createSlice({
  name: 'messages',
  initialState: [],
  reducers: {
    addMessage: (state, action) => {
      const { id, message, sender, direction } = action.payload;
      const messageToUpdate = state.find((msg) => msg.id === id);
      if (messageToUpdate) {
        messageToUpdate.message = message;
      } else {
        state.push({ id, message, sender, direction });
      }
    },
    clearMessages: (state) => {
      return [];
    },
  },
});

export const { addMessage, clearMessages } = messagesSlice.actions;

export default messagesSlice.reducer;
