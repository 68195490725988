import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';

const useAnalytics = () => {
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
    }
  }, []);

  const logEvent = (category, action, label = null) => {
    const eventParams = {
      category,
      action,
      label,
      user_id: user.username || 'anonymous',
      user_email: user.email || 'anonymous',
    };

    if (process.env.NODE_ENV === 'production') {
      ReactGA.event(eventParams);
    } else {
      console.log('Analytics Event:', eventParams);
    }
  };

  const logPageView = (path) => {
    const pageViewParams = {
      page: path,
      user_id: user.username || 'anonymous',
      user_email: user.email || 'anonymous',
    };

    if (process.env.NODE_ENV === 'production') {
      ReactGA.send({ hitType: "pageview", ...pageViewParams });
    } else {
      console.log('Analytics PageView:', pageViewParams);
    }
  };

  return { logEvent, logPageView };
};

export default useAnalytics;
