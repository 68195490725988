import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { updateUser } from '../../ducks/slices/userSlice';
import {
  Box,
  VStack,
  Heading,
  Text,
  Card,
  CardHeader,
  CardBody,
  Avatar,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
  useToast,
  Alert,
  AlertIcon,
  Divider,
  Icon,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { EditIcon, DownloadIcon, DeleteIcon, AddIcon } from '@chakra-ui/icons';
import { FaFilePdf } from 'react-icons/fa';

const Profile = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const toast = useToast();

  const [isEditing, setIsEditing] = useState('');
  const [editValue, setEditValue] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');

  const [hasResume, setHasResume] = useState(false);
  const [isUploadingResume, setIsUploadingResume] = useState(false);
  const [resumePreviewUrl, setResumePreviewUrl] = useState(null);

  useEffect(() => {
    checkResume();
  }, []);

  const checkResume = async () => {
    try {
      const response = await axios.get('/resume/get-resume');
      setHasResume(response.status === 200);
      
      if (response.status === 200) {
        const urlResponse = await axios.get('/resume/get-resume-url');
        console.log(urlResponse.data);
        setResumePreviewUrl(urlResponse.data.url);
      }
    } catch (error) {
      setHasResume(false);
      setResumePreviewUrl(null);
    }
  };

  const handleResumeUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (file.type !== 'application/pdf') {
      toast({
        title: 'Error',
        description: 'Only PDF files are accepted',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      setIsUploadingResume(true);
      await axios.post('/resume/upload-resume', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setHasResume(true);
      toast({
        title: 'Success',
        description: 'Resume uploaded successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: error.response?.data || 'Error uploading resume',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsUploadingResume(false);
    }
  };

  const handleDownloadResume = async () => {
    try {
      const response = await axios.get('/resume/get-resume', {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${user.firstName}_${user.lastName}_Resume.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Error downloading resume',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteResume = async () => {
    try {
      await axios.post('/resume/delete-resume');
      setHasResume(false);
      toast({
        title: 'Success',
        description: 'Resume deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Error deleting resume',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEdit = (field, value) => {
    setEditValue(value);
    setIsEditing(field);
  };

  const handleClose = () => {
    setIsEditing('');
    setEditValue('');
    setCurrentPassword('');
    setNewPassword('');
    setError('');
  };

  const handleSave = async () => {
    try {
      let endpoint = '';
      let payload = {};

      switch (isEditing) {
        case 'username':
          endpoint = '/user/update/username';
          payload = { username: editValue };
          break;
        case 'email':
          endpoint = '/user/update/email';
          payload = { email: editValue };
          break;
        case 'firstName':
          endpoint = '/user/update/first-name';
          payload = { firstName: editValue };
          break;
        case 'lastName':
          endpoint = '/user/update/last-name';
          payload = { lastName: editValue };
          break;
        case 'password':
          endpoint = '/user/update/password';
          payload = { currentPassword, newPassword };
          break;
        default:
          return;
      }

      const response = await axios.put(endpoint, payload);
      
      if (isEditing !== 'password') {
        dispatch(updateUser({
          ...user,
          [isEditing]: response.data[isEditing === 'firstName' ? 'first_name' : 
                       isEditing === 'lastName' ? 'last_name' : isEditing]
        }));
      }

      toast({
        title: 'Success',
        description: `${isEditing.charAt(0).toUpperCase() + isEditing.slice(1)} updated successfully`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      handleClose();
    } catch (error) {
      setError(error.response?.data || 'An error occurred');
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={6}
      mt={32}
      px={4}
      maxW="1400px"
      w="100%"
    >
      <Card 
        w="40%"
        borderWidth="2px" 
        borderRadius="xl" 
        overflow="visible"
        bg="#1e1e1e" 
        color="white"
        boxShadow="0px 4px 20px rgba(0, 0, 0, 0.2)"
        height="fit-content"
      >
        <CardHeader>
          <VStack spacing={6} align="center">
            <Avatar 
              size="2xl" 
              name={`${user.firstName} ${user.lastName}`} 
              src="" 
              bg="#673FD7"
            />
            <Heading size="lg">
              {user.firstName} {user.lastName}
            </Heading>
          </VStack>
        </CardHeader>
        <CardBody>
          <VStack spacing={6} align="start">
            <Box w="100%">
              <Text color="gray.400" fontSize="sm">Username</Text>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Text fontSize="lg">{user.username}</Text>
                <Button
                  leftIcon={<EditIcon />}
                  size="sm"
                  bg="#673FD7"
                  color="white"
                  onClick={() => handleEdit('username', user.username)}
                  isDisabled={user.githubId || user.linkedinId}
                >
                  Edit
                </Button>
              </Box>
            </Box>
            <Box w="100%">
              <Text color="gray.400" fontSize="sm">Email</Text>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Text fontSize="lg">{user.email}</Text>
                <Button
                  leftIcon={<EditIcon />}
                  size="sm"
                  bg="#673FD7"
                  color="white"
                  onClick={() => handleEdit('email', user.email)}
                >
                  Edit
                </Button>
              </Box>
            </Box>
            <Box w="100%">
              <Text color="gray.400" fontSize="sm">First Name</Text>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Text fontSize="lg">{user.firstName}</Text>
                <Button
                  leftIcon={<EditIcon />}
                  size="sm"
                  bg="#673FD7"
                  color="white"
                  onClick={() => handleEdit('firstName', user.firstName)}
                >
                  Edit
                </Button>
              </Box>
            </Box>
            <Box w="100%">
              <Text color="gray.400" fontSize="sm">Last Name</Text>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Text fontSize="lg">{user.lastName}</Text>
                <Button
                  leftIcon={<EditIcon />}
                  size="sm"
                  bg="#673FD7"
                  color="white"
                  onClick={() => handleEdit('lastName', user.lastName)}
                >
                  Edit
                </Button>
              </Box>
            </Box>
            <Box w="100%">
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Text color="gray.400" fontSize="sm">Password</Text>
                <Button
                  leftIcon={<EditIcon />}
                  size="sm"
                  bg="#673FD7"
                  color="white"
                  onClick={() => handleEdit('password', '')}
                  isDisabled={user.githubId || user.linkedinId}
                >
                  Change Password
                </Button>
              </Box>
            </Box>
          </VStack>
        </CardBody>
      </Card>

      <Card 
        w="60%"
        borderWidth="2px" 
        borderRadius="xl" 
        overflow="visible"
        bg="#1e1e1e" 
        color="white"
        boxShadow="0px 4px 20px rgba(0, 0, 0, 0.2)"
        height="fit-content"
      >
        <CardBody>
          <Box mb={4}>
            <Text color="gray.400" fontSize="md">
              Your resume helps us personalize your interview experience and match you with relevant opportunities. We use it to:
              <UnorderedList mt={2} spacing={2}>
                <ListItem>Tailor technical questions to your experience level</ListItem>
                <ListItem>Connect you with companies seeking candidates with your skill set</ListItem>
                <ListItem>Provide more meaningful feedback on your interview performance</ListItem>
              </UnorderedList>
            </Text>
          </Box>
          <Text color="gray.400" fontSize="sm" mb={4}>Resume</Text>
          <Box 
            display="flex" 
            flexDirection="column" 
            gap={4}
            position="relative"
            zIndex={1}
          >
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box display="flex" alignItems="center" gap={4}>
                <Icon as={FaFilePdf} w={6} h={6} color={hasResume ? "green.400" : "gray.400"} />
                <Text fontSize="lg">
                  {hasResume ? "Resume uploaded" : "No resume uploaded"}
                </Text>
              </Box>
              <Box display="flex" gap={2}>
                {hasResume ? (
                  <>
                    <Button
                      leftIcon={<DownloadIcon />}
                      size="sm"
                      bg="#673FD7"
                      color="white"
                      onClick={handleDownloadResume}
                    >
                      Download
                    </Button>
                    <Button
                      leftIcon={<DeleteIcon />}
                      size="sm"
                      colorScheme="red"
                      variant="ghost"
                      onClick={handleDeleteResume}
                    >
                      Delete
                    </Button>
                  </>
                ) : (
                  <Button
                    as="label"
                    htmlFor="resume-upload"
                    leftIcon={<AddIcon />}
                    size="sm"
                    bg="#673FD7"
                    color="white"
                    cursor="pointer"
                    isLoading={isUploadingResume}
                  >
                    Upload Resume
                    <input
                      id="resume-upload"
                      type="file"
                      accept=".pdf"
                      style={{ display: 'none' }}
                      onChange={handleResumeUpload}
                    />
                  </Button>
                )}
              </Box>
            </Box>
            
            {hasResume && resumePreviewUrl && (
              <Box
                mt={4}
                borderRadius="md"
                overflow="hidden"
                border="1px solid"
                borderColor="gray.600"
                height="800px"
                position="relative"
              >
                <iframe
                  src={`${resumePreviewUrl}#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0`}
                  style={{
                    width: '100%',
                    height: '100%',
                    border: 'none',
                  }}
                  title="Resume Preview"
                >
                  <p>Unable to display PDF. <a href={resumePreviewUrl} target="_blank" rel="noopener noreferrer">Click here to download</a></p>
                </iframe>
              </Box>
            )}
          </Box>
        </CardBody>
      </Card>

      <Modal isOpen={!!isEditing} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent bg="#1e1e1e" color="white">
          <ModalHeader>
            Edit {isEditing.charAt(0).toUpperCase() + isEditing.slice(1)}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {error && (
              <Alert status="error" mb={4} bg="red.900" color="white">
                <AlertIcon color="red.200" />
                {error}
              </Alert>
            )}
            {isEditing === 'password' ? (
              <VStack spacing={4}>
                <FormControl>
                  <FormLabel>Current Password</FormLabel>
                  <Input
                    type="password"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    bg="gray.700"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>New Password</FormLabel>
                  <Input
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    bg="gray.700"
                  />
                </FormControl>
              </VStack>
            ) : (
              <FormControl>
                <FormLabel>{isEditing.charAt(0).toUpperCase() + isEditing.slice(1)}</FormLabel>
                <Input
                  value={editValue}
                  onChange={(e) => setEditValue(e.target.value)}
                  bg="gray.700"
                />
              </FormControl>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={handleClose}>
              Cancel
            </Button>
            <Button bg="#673FD7" color="white" onClick={handleSave}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Profile; 