import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Heading,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { useNavigate } from 'react-router-dom';

const InterviewStatsCard = () => {
  const [scores, setScores] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const placeholderData = [
    { date: 'Jan 1', score: 5 },
    { date: 'Feb 12', score: 7 },
    { date: 'Mar 23', score: 6 },
    { date: 'Apr 14', score: 8 },
    { date: 'May 5', score: 7 }
  ];

  useEffect(() => {
    const fetchScores = async () => {
      try {
        const response = await axios.get('/info/assessment-scores');
        const formattedScores = response.data.map(score => ({
          score: score.overall_score,
          date: new Date(score.created_at).toLocaleDateString(undefined, {
            month: 'short',
            day: 'numeric'
          })  // Will show dates like "Jan 15" instead of "01/15/2024"
        }));
        setScores(formattedScores);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching scores:', error);
        setIsLoading(false);
      }
    };

    fetchScores();
  }, []);

  if (isLoading) {
    return <Box>Loading...</Box>;
  }

  // Replace the empty state check with this
  if (scores.length === 0) {
    return (
      <Box
        bg="#1e1e1e"
        p={6}
        borderRadius="lg"
        boxShadow="xl"
        color="white"
        height="400px"
        position="relative"
        cursor="pointer"
        onClick={() => navigate('/interview')}
        _hover={{
          '& > div:last-child': {
            bg: 'rgba(26, 32, 44, 0.9)'
          }
        }}
      >
        <Heading size="md" mb={4}>Interview Performance</Heading>
        <ResponsiveContainer width="100%" height="85%">
          <LineChart data={placeholderData}>
            <CartesianGrid strokeDasharray="3 3" stroke="rgba(255, 255, 255, 0.05)" />
            <XAxis 
              dataKey="date" 
              stroke="#4A5568"
              tick={{ fill: '#4A5568' }}
            />
            <YAxis 
              domain={[0, 10]} 
              stroke="#4A5568"
              tick={{ fill: '#4A5568' }}
            />
            <Line 
              type="monotone" 
              dataKey="score" 
              stroke="#4A5568" 
              strokeWidth={2}
              dot={{ fill: '#4A5568' }}
            />
          </LineChart>
        </ResponsiveContainer>
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bg="rgba(26, 32, 44, 0.8)"
          borderRadius="lg"
          transition="background-color 0.2s"
        >
          <Text color="gray.300">This graph looks lonely... Click to add some data! 📈</Text>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      bg="#1e1e1e"
      p={6}
      borderRadius="lg"
      boxShadow="xl"
      color="white"
      height="400px"
    >
      <Heading size="md" mb={4}>Interview Performance</Heading>
      <ResponsiveContainer width="100%" height="85%">
        <LineChart data={scores}>
          <CartesianGrid strokeDasharray="3 3" stroke="rgba(255, 255, 255, 0.1)" />
          <XAxis 
            dataKey="date" 
            stroke="#e0e0e0"
            tick={{ fill: '#e0e0e0' }}
          />
          <YAxis 
            domain={[0, 10]} 
            stroke="#e0e0e0"
            tick={{ fill: '#e0e0e0' }}
          />
          <Tooltip
            contentStyle={{
              backgroundColor: '#2c2c2c',
              border: 'none',
              borderRadius: '4px',
              color: '#e0e0e0'
            }}
          />
          <Line 
            type="monotone" 
            dataKey="score" 
            stroke="#673FD7" 
            strokeWidth={2}
            dot={{ fill: '#673FD7' }}
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default InterviewStatsCard;
