import React from 'react';
import './SmallScreenView.scss';
import Logo from '../../Components/Logo/Logo';

const SmallScreenView = () => {
  return (
    <div className="small-screen-view">
      <div className="content">
        <div className="header">
          <h1>Canditest AI</h1>
          <Logo width="40px" height="40px" />
        </div>
        <p className="message">Oops! Looks like you're trying to code on a tiny screen... 🔍</p>
        <p className="submessage">Our AI interviewer needs more space to properly assess your amazing coding skills!</p>
        <div className="fun-message">
          <p>Meanwhile...</p>
          <ul>
            <li>☕ Grab a coffee</li>
            <li>💻 Find a bigger screen</li>
            <li>🚀 Come back ready to code!</li>
          </ul>
        </div>
        <p className="demo-text">Want a sneak peek? Check out our demo!</p>
        <div className="video-container">
          <iframe
            src="https://www.youtube.com/embed/CnQSML8d3tk?autoplay=1&fs=1"
            title="Canditest AI Demo"
            style={{ border: 'none' }}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default SmallScreenView;