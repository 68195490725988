import { createSlice } from '@reduxjs/toolkit';

const transcriptSlice = createSlice({
  name: 'transcript',
  initialState: {
    partialTranscript: ''
  },
  reducers: {
    setPartialTranscript: (state, action) => {
      state.partialTranscript += " " + action.payload;
    },
    clearPartialTranscript: (state) => {
      console.log("Clearing partial transcript")
      state.partialTranscript = '';
    }
  },
});

export const { setPartialTranscript, clearPartialTranscript } = transcriptSlice.actions;

export default transcriptSlice.reducer; 