import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  Box,
  VStack,
  Heading,
  Text,
  Input,
  Button,
  Alert,
  AlertIcon,
  InputGroup,
  InputRightElement,
  Icon,
} from "@chakra-ui/react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { ChevronLeftIcon } from '@chakra-ui/icons';
import Logo from "../../Components/Logo/Logo";
import "./ResetPassword.scss";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();

  const handleResetPassword = async () => {
    setErrors([]);
    if (!password || !confirmPassword) {
      setErrors(["Please fill in all fields"]);
      return;
    }
    if (password !== confirmPassword) {
      setErrors(["Passwords do not match"]);
      return;
    }
    try {
      await axios.post(`/auth/reset-password`, { token, password });
      setSuccess(true);
    } catch (error) {
      setErrors([error.response?.data || "An error occurred. Please try again."]);
    }
  };

  if (success) {
    return (
      <Box className="reset-password-page">
        <div className="reset-password-gradient-background" />
        <div className="reset-password-content">
          <Box
            className="reset-password-card"
            bg="rgba(24, 24, 27, 0.9)"
            backdropFilter="blur(10px)"
          >
            <VStack spacing={6} align="stretch">
              <Box display="flex" justifyContent="center" mb={4}>
                <Logo width="40px" height="40px" />
              </Box>

              <Heading size="lg" textAlign="center" color="white">
                Password Reset Successful
              </Heading>

              <Text color="gray.400" textAlign="center">
                Your password has been reset successfully.
              </Text>

              <Button
                bg="#673FD7"
                color="white"
                size="lg"
                _hover={{ bg: "#5a36bc" }}
                onClick={() => navigate("/auth/signin")}
                height="56px"
                fontSize="md"
              >
                Back to Sign In
              </Button>
            </VStack>
          </Box>
        </div>
      </Box>
    );
  }

  return (
    <Box className="reset-password-page">
      <div className="reset-password-gradient-background" />
      <div className="reset-password-content">
        <Box
          className="reset-password-card"
          bg="rgba(24, 24, 27, 0.9)"
          backdropFilter="blur(10px)"
        >
          <VStack spacing={6} align="stretch">
            <Box position="absolute" top={6} left={6}>
              <Button
                leftIcon={<ChevronLeftIcon width={6} height={6} />}
                variant="ghost"
                color="gray.400"
                _hover={{ color: "white" }}
                onClick={() => navigate("/auth/signin")}
                p={0}
              />
            </Box>

            <Box display="flex" justifyContent="center" mb={4}>
              <Logo width="40px" height="40px" />
            </Box>

            <Heading size="lg" textAlign="center" color="white">
              Reset Your Password
            </Heading>

            {errors.length > 0 && (
              <VStack spacing={2}>
                {errors.map((error, index) => (
                  <Alert
                    key={index}
                    status="error"
                    borderRadius="md"
                    bg="red.900"
                    color="white"
                  >
                    <AlertIcon color="red.200" />
                    {error}
                  </Alert>
                ))}
              </VStack>
            )}

            <VStack spacing={4}>
              <Box width="100%">
                <Text color="gray.400" mb={2} fontSize="sm">
                  New Password
                </Text>
                <InputGroup size="lg">
                  <Input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    bg="gray.700"
                    border="none"
                    color="white"
                    _hover={{ bg: "gray.600" }}
                    _focus={{ bg: "gray.600", boxShadow: "none" }}
                  />
                  <InputRightElement width="3rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      bg="transparent"
                      _hover={{ bg: "transparent" }}
                      _active={{ bg: "transparent" }}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <Icon
                        as={showPassword ? FaEyeSlash : FaEye}
                        color="gray.400"
                        _hover={{ color: "white" }}
                      />
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </Box>

              <Box width="100%">
                <Text color="gray.400" mb={2} fontSize="sm">
                  Confirm Password
                </Text>
                <InputGroup size="lg">
                  <Input
                    type={showPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    bg="gray.700"
                    border="none"
                    color="white"
                    _hover={{ bg: "gray.600" }}
                    _focus={{ bg: "gray.600", boxShadow: "none" }}
                  />
                  <InputRightElement width="3rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      bg="transparent"
                      _hover={{ bg: "transparent" }}
                      _active={{ bg: "transparent" }}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <Icon
                        as={showPassword ? FaEyeSlash : FaEye}
                        color="gray.400"
                        _hover={{ color: "white" }}
                      />
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </Box>
            </VStack>

            <Button
              bg="#673FD7"
              color="white"
              size="lg"
              _hover={{ bg: "#5a36bc" }}
              onClick={handleResetPassword}
              height="56px"
              fontSize="md"
            >
              Reset Password
            </Button>
          </VStack>
        </Box>
      </div>
    </Box>
  );
};

export default ResetPassword; 