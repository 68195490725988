import "./Dashboard.scss";
import Logo from "../../Components/Logo/Logo";
import { FaHome, FaUserTie, FaClipboardList, FaUser, FaSignOutAlt } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import Home from "../../Components/Home/Home";
import { useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { updateUser } from '../../ducks/slices/userSlice';
import Assessments from "../Assessments/Assessments";
import Profile from "../../Components/Profile/Profile";

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isHomeActive = location.pathname === '/dashboard';
  const isInterviewActive = location.pathname === '/dashboard/interview';
  const isAssessmentsActive = location.pathname === '/dashboard/assessments';
  const isProfileActive = location.pathname === '/dashboard/profile';

  useEffect(() => {
    checkPrivacy();
  }, []);

  const checkPrivacy = async () => {
    try {
      const res = await axios.get(`/auth/getUser`);
      console.log("res", res);
      dispatch(
        updateUser({
          userId: res.data.user_id,
          username: res.data.username,
          email: res.data.email,
          firstName: res.data.first_name,
          lastName: res.data.last_name,
          githubId: res.data.github_id,
          linkedinId: res.data.linkedin_id,
        })
      );
    } catch {
      dispatch(updateUser({}));
      navigate("/");
    }
  };

  const logout = async () => {
    try {
      await axios.post(`/auth/logout`);
      dispatch(updateUser({}));
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-sidenav">
        <div className="dashboard-sidenav-header">
          <p>Canditest AI</p>
          <Logo width="30px" height="30px" />
        </div>
        <div className="dashboard-sidenav-body">
          <button 
            className={`sidenav-button ${isHomeActive ? 'active' : ''}`}
            onClick={() => navigate('/dashboard')}
          >
            <FaHome style={{ marginRight: '8px' }} />
            Home
          </button>
          <button 
            className={`sidenav-button ${isAssessmentsActive ? 'active' : ''}`}
            onClick={() => navigate('/dashboard/assessments')}
          >
            <FaClipboardList style={{ marginRight: '8px' }} />
            Assessments
          </button>
          <button 
            className={`sidenav-button ${isProfileActive ? 'active' : ''}`}
            onClick={() => navigate('/dashboard/profile')}
          >
            <FaUser style={{ marginRight: '8px' }} />
            Profile
          </button>
        </div>
        <div className="dashboard-sidenav-footer">
          <button className="sidenav-button" onClick={logout}>
            <FaSignOutAlt style={{ marginRight: '8px'}} />
            Logout
          </button>
        </div>
      </div>
      <div className="dashboard-content">
        {isHomeActive && <Home />}
        {isAssessmentsActive && <Assessments />}
        {isProfileActive && <Profile />}
      </div>
    </div>
  );
};

export default Dashboard;
