import React from 'react';


const Logo = ({ width, height }) => {
  return (
    <img 
      src="/jellybean7.svg"
      alt="Jellybean Logo"
      style={{
        width: width,
        height: height,
        filter: 'drop-shadow(3px 3px 4px rgba(0,0,0,0.3))'
      }}
    />
  );
};

export default Logo;
