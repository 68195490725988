import React, { useState } from 'react';
import {
  Box,
  Heading,
  Text,
  Textarea,
  Button,
  useToast,
  VStack,
} from '@chakra-ui/react';
import axios from 'axios';
import useAnalytics from '../../analytics/useAnalytics';

const FeedbackCard = () => {
  const [feedback, setFeedback] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const { logEvent } = useAnalytics();

  const handleSubmit = async () => {
    if (!feedback.trim()) {
      toast({
        title: "Error",
        description: "Please enter your feedback before submitting",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsSubmitting(true);
    try {
      await axios.post('/feedback/submit', { feedback });
      logEvent('Feedback', 'Submit Feedback');
      toast({
        title: "Success",
        description: "Thank you for your feedback!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setFeedback('');
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to submit feedback. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsSubmitting(false);
  };

  return (
    <Box bg="#1e1e1e" p={6} borderRadius="lg" boxShadow="xl" color="white" height="400px">
      <VStack align="stretch" height="100%" spacing={4}>
        <Heading size="md">Send Feedback</Heading>
        <Text color="gray.400">
          Help us improve! Share your thoughts, suggestions, or report any issues.
        </Text>
        <Textarea
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          placeholder="Type your feedback here..."
          size="md"
          resize="none"
          flex="1"
          bg="rgba(255, 255, 255, 0.1)"
          border="none"
          _hover={{ bg: "gray.600" }}
          _focus={{ 
            bg: "gray.600", 
            borderColor: "transparent",
            boxShadow: "none",
            outline: "none"
          }}
          _active={{
            bg: "gray.600",
            borderColor: "transparent",
            boxShadow: "none",
            outline: "none"
          }}
        />
        <Button
          bg="#673FD7"
          color="white"
          _hover={{ 
            bg: '#5a36bc',
            transform: 'translateY(-2px)',
            transition: 'transform 0.2s',
          }}
          onClick={handleSubmit}
          isLoading={isSubmitting}
          alignSelf="flex-end"
        >
          Submit Feedback
        </Button>
      </VStack>
    </Box>
  );
};

export default FeedbackCard;
