import "./AssessmentV2.scss";
import { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import CodingTimeline from "../../Components/CodingTimeline/CodingTimeline";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { ChevronLeft } from 'lucide-react';

const AssessmentV2 = () => {
  const { assessmentId } = useParams();
  const navigate = useNavigate();
  const [interactions, setInteractions] = useState([]);
  const [timeLimit, setTimeLimit] = useState(0);
  const [isLoading, setIsLoading] = useState(true);  // Add loading state
  const messageListRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [assessment, setAssessment] = useState({
    overallScore: 0,
    overallFeedback: "",
    categories: [],
    strengths: [],
    areasForImprovement: [],
    finalRecommendation: "",
    difficulty: "",
  });

  useEffect(() => {
    const fetchAssessment = async () => {
      setIsLoading(true);  // Start loading
      try {
        const response = await axios.get(`/info/assessments/${assessmentId}`);
        setTimeLimit(response.data.time_limit);
        setAssessment({
          overallScore: response.data.overall_score,
          overallFeedback: response.data.overall_feedback,
          categories: response.data.categories,
          strengths: response.data.strengths,
          areasForImprovement: response.data.areas_for_improvement,
          finalRecommendation: response.data.final_recommendation,
          difficulty: response.data.difficulty,
        });

        const interactionsResponse = await axios.get(
          `/info/interactions/${assessmentId}`
        );

        const normalizedInteractions = normalizeInteractionTimestamps(
          interactionsResponse.data,
          response.data.time_limit
        );

        setInteractions(normalizedInteractions);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          navigate("/dashboard/assessments");
        } else if (error.response && error.response.status === 401) {
          navigate("/auth/signin");
        } else {
          console.error("Error fetching assessment:", error);
        }
      } finally {
        setIsLoading(false);  // End loading regardless of success/failure
      }
    };
    fetchAssessment();
  }, [assessmentId]);

  const normalizeInteractionTimestamps = (interactions, timeLimit) => {
    if (interactions.length === 0) return [];
    const startTime = new Date(interactions[0].created_at).getTime();
    
    return interactions.map(interaction => {
      const timestamp = new Date(interaction.created_at).getTime();
      const normalizedTime = Math.min(
        ((timestamp - startTime) / timeLimit) * timeLimit,
        timeLimit
      );
      
      return {
        type: interaction.interaction_type,
        message: interaction.message,
        interviewerThoughts: interaction.interviewer_thoughts,
        timestamp: interaction.created_at,
        normalizedTimestamp: normalizedTime,
        candidateCoderPad: interaction.candidateCoderPad,
      };
    });
  };

  const formatTime = (ms) => {
    const minutes = Math.floor(ms / 60000);
    const seconds = Math.floor((ms % 60000) / 1000);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const renderInteractions = (currentTime) => {
    return interactions.map((interaction, index) => {
      const timestamp = formatTime(interaction.normalizedTimestamp);

      const handleMessageClick = () => {
        const percentage = (interaction.normalizedTimestamp / timeLimit) * 100;
        const event = new CustomEvent('timelineSync', { 
          detail: { percentage } 
        });
        window.dispatchEvent(event);
      };

      if (interaction.type === "AIMessage" && interaction.message) {
        return (
          <div 
            key={index} 
            className="message-block clickable"
            id="incoming"
            onClick={handleMessageClick}
          >
            <div className="message">
              <div className="message-header">
                <div className="message-label">Interviewer</div>
                <div className="message-timestamp">{timestamp}</div>
              </div>
              {interaction.message}
            </div>
          </div>
        );
      } else if (interaction.type === "HumanMessage" && interaction.message) {
        return (
          <div 
            key={index} 
            className="message-block clickable"
            id="outgoing"
            onClick={handleMessageClick}
          >
            <div className="message">
              <div className="message-header">
                <div className="message-label">Candidate</div>
                <div className="message-timestamp">{timestamp}</div>
              </div>
              {interaction.message}
            </div>
          </div>
        );
      }
      return null;
    });
  };

  const handleTimelineChange = (time) => {
    setCurrentTime(time);
  };

  const getBarColor = (score) => {
    if (score <= 3) return "#ff4d4d";
    if (score <= 7) return "#ffd700";
    return "#4caf50";
  };

  const handleReferenceClick = (reference) => {
    console.log(reference);
  };

  return (
    <div className="assessment-v2-page">
      <button
        className="back-button"
        onClick={() => navigate('/dashboard/assessments')}
      >
        <ChevronLeft />
      </button>
      <div className="assessment-v2-page-left">
        <div className="assessment-content">
          <div className="assessment-header">
            <div className="assessment-meta">
              <p>
                <span>Difficulty:</span> {assessment.difficulty || "N/A"}
              </p>
              <p>
                <span>Time Limit:</span>{" "}
                {timeLimit ? `${timeLimit / 60000} minutes` : "N/A"}
              </p>
            </div>
            <div className="overall-score">
              <h2>
                Overall Score:{" "}
                {isLoading ? (
                  <div className="loading-message">
                    <span>Assessment is being prepared...</span>
                    <p className="refresh-hint">Refresh page to see results</p>
                  </div>
                ) : (
                  <>
                    <span
                      className={`score ${
                        assessment.overallScore <= 3
                          ? "low"
                          : assessment.overallScore <= 7
                          ? "medium"
                          : "high"
                      }`}
                    >
                      {assessment.overallScore || "N/A"}
                    </span>
                    /10
                  </>
                )}
              </h2>
            </div>
          </div>

          <div className="assessment-section overall-feedback">
            <h2>Overall Feedback</h2>
            <p>{assessment.overallFeedback || "No overall feedback available."}</p>
          </div>

          <div className="assessment-section category-scores">
            <h2>Category Scores</h2>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                data={assessment.categories}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" stroke="rgba(255, 255, 255, 0.1)" />
                <XAxis
                  dataKey="name"
                  tick={{ fontSize: 12, fill: "#e0e0e0" }}
                  interval={0}
                  angle={-45}
                  textAnchor="end"
                  height={80}
                />
                <YAxis domain={[0, 10]} tick={{ fontSize: 12, fill: "#e0e0e0" }} />
                <Tooltip
                  contentStyle={{
                    backgroundColor: "#2c2c2c",
                    border: "none",
                    borderRadius: "4px",
                  }}
                  labelStyle={{ color: "#e0e0e0" }}
                  itemStyle={{ color: "#e0e0e0" }}
                />
                <Bar dataKey="score">
                  {assessment.categories.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={getBarColor(entry.score)} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>

          <div className="assessment-section detailed-feedback">
            <h2>Detailed Feedback</h2>
            {assessment.categories &&
              assessment.categories.map((category, index) => (
                <div key={index} className="category-feedback">
                  <h3>
                    {category.name + " - "}
                    <span
                      className={`score ${
                        category.score <= 3
                          ? "low"
                          : category.score <= 7
                          ? "medium"
                          : "high"
                      }`}
                    >
                      {category.score || "N/A"}
                    </span>
                    /10
                  </h3>
                  <p>
                    <strong>Feedback:</strong>{" "}
                    {category.feedback ||
                      "No feedback available for this category."}
                  </p>
                  <p onClick={() => {
                    category.reference && handleReferenceClick(category.reference);
                  }}>
                    <strong>Reference:</strong>{" "}
                    {category.reference?.message ||
                      "No reference available for this category."}
                  </p>
                </div>
              ))}
          </div>

          <div className="assessment-section strengths-and-improvements">
            <div className="strengths">
              <h2>Strengths</h2>
              {assessment.strengths && assessment.strengths.length > 0 ? (
                <ul>
                  {assessment.strengths.map((strength, index) => (
                    <li key={index}>{strength}</li>
                  ))}
                </ul>
              ) : (
                <p>No strengths listed.</p>
              )}
            </div>

            <div className="improvement-areas">
              <h2>Areas for Improvement</h2>
              {assessment.areasForImprovement && assessment.areasForImprovement.length > 0 ? (
                <ul>
                  {assessment.areasForImprovement.map((area, index) => (
                    <li key={index}>{area}</li>
                  ))}
                </ul>
              ) : (
                <p>No areas for improvement listed.</p>
              )}
            </div>
          </div>

          <div className="assessment-section final-recommendation">
            <h2>Final Recommendation</h2>
            <p>{assessment.finalRecommendation || "No final recommendation available."}</p>
          </div>
        </div>
      </div>
      <div className="assessment-v2-page-right">
        <div className="assessment-v2-page-right-top">
          <CodingTimeline 
            interactions={interactions} 
            timeLimit={timeLimit}
            onTimeChange={handleTimelineChange} 
          />
        </div>
        <div className="assessment-v2-page-right-bottom">
          <div className="transcript-header">Transcript</div>
          <div className="message-list" ref={messageListRef}>
            {renderInteractions(currentTime)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentV2;
