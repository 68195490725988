import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Box,
  VStack,
  Heading,
  Text,
  Input,
  Button,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import Logo from "../../Components/Logo/Logo";
import "./ForgotPassword.scss";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();

  const handleForgotPassword = async () => {
    setErrors([]);
    if (!email) {
      setErrors(["Please enter your email address"]);
      return;
    }
    try {
      await axios.post(`/auth/forgot-password`, { email });
      setIsSubmitted(true);
    } catch (error) {
      setErrors([error.response?.data || "An error occurred. Please try again."]);
    }
  };

  if (isSubmitted) {
    return (
      <Box className="forgot-password-page">
        <div className="forgot-password-gradient-background" />
        <div className="forgot-password-content">
          <Box
            className="forgot-password-card"
            bg="rgba(24, 24, 27, 0.9)"
            backdropFilter="blur(10px)"
          >
            <VStack spacing={6} align="stretch">
              <Box display="flex" justifyContent="center" mb={4}>
                <Logo width="40px" height="40px" />
              </Box>

              <Heading size="lg" textAlign="center" color="white">
                Check Your Email
              </Heading>

              <Text color="gray.400" textAlign="center">
                If an account exists with this email, you will receive a password reset link.
              </Text>

              <Button
                variant="outline"
                color="white"
                borderColor="gray.600"
                _hover={{ bg: "gray.700" }}
                onClick={() => navigate("/auth/signin")}
              >
                Back to Sign In
              </Button>
            </VStack>
          </Box>
        </div>
      </Box>
    );
  }

  return (
    <Box className="forgot-password-page">
      <div className="forgot-password-gradient-background" />
      <div className="forgot-password-content">
        <Box
          className="forgot-password-card"
          bg="rgba(24, 24, 27, 0.9)"
          backdropFilter="blur(10px)"
        >
          <VStack spacing={6} align="stretch">
            <Box display="flex" justifyContent="center" mb={4}>
              <Logo width="40px" height="40px" />
            </Box>

            <Heading size="lg" textAlign="center" color="white">
              Reset Your Password
            </Heading>

            {errors.length > 0 && (
              <VStack spacing={2}>
                {errors.map((error, index) => (
                  <Alert
                    key={index}
                    status="error"
                    borderRadius="md"
                    bg="red.900"
                    color="white"
                  >
                    <AlertIcon color="red.200" />
                    {error}
                  </Alert>
                ))}
              </VStack>
            )}

            <Box width="100%">
              <Text color="gray.400" mb={2} fontSize="sm">
                Email Address
              </Text>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                size="lg"
                bg="gray.700"
                border="none"
                color="white"
                _hover={{ bg: "gray.600" }}
                _focus={{ bg: "gray.600", boxShadow: "none" }}
              />
            </Box>

            <Button
              bg="#673FD7"
              color="white"
              size="lg"
              _hover={{ bg: "#5a36bc" }}
              onClick={handleForgotPassword}
              height="56px"
              fontSize="md"
            >
              Send Reset Link
            </Button>

            <Text color="gray.400" fontSize="sm" textAlign="center">
              Remember your password?{" "}
              <Button
                variant="link"
                color="#673FD7"
                onClick={() => navigate("/auth/signin")}
              >
                Sign In
              </Button>
            </Text>
          </VStack>
        </Box>
      </div>
    </Box>
  );
};

export default ForgotPassword; 